<template>
<v-app id="inspire">
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-12">
                        <v-toolbar color="primary" dark flat>
                            <v-toolbar-title>Authentication</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-form>
                                <v-text-field @keyup.enter="login()" label="Login" name="login" prepend-icon="mdi-account" type="text" v-model="email"></v-text-field>
                                <v-text-field @keyup.enter="login()" id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password" v-model="password"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn size="large" :loading="loginState" color="primary" class="mb-2" @click="login()">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import {
    mapState
} from 'vuex';
import router from '@/router';

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            snackbar: false,
        }
    },
    props: {
        source: String,
    },
    computed: {
        ...mapState('auth', {
            loginState: 'isAuthenticatePending'
        }),
    },
    methods: {

        login() {
            this.$store.dispatch('auth/authenticate', {
                strategy: 'local',
                email: this.email,
                password: this.password
            }).then(() => {
                router.push('/');
            }).catch(e => {
                console.log(e)
                this.$toasted.global.error({
                    message: e.message
                });
            })
        },

    }

}
</script>

<style>

</style>
